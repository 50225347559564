import { moneyEventTypes } from "@/enums/moneyManager";
import { EventBus } from "@/internal";
import { messagesService } from "@/services/messages.service";
import { moneyService } from "@/services/money.service";
import { Component, Prop, Vue } from "vue-property-decorator";
import { PayoutSlipTillComponent } from "../print/payout/PayoutSlipTill.component";
import Template from "./PayoutTill.template.vue";

@Component({
  mixins: [Template]
})
export default class PayoutTillComponent extends Vue {
  @Prop({ default: null })
  public item!: {
    employee: Record<string, number>;
    cash_assigned: number;
    cash_retail: number;
    boxId: number;
  };
  public isFormValid = false;
  public isLoading = false;
  public model: {
    amount: string;
    sub_type: string;
    description: string;
  } = {
    amount: "",
    sub_type: moneyEventTypes.PayoutTill,
    description: ""
  };

  constructor() {
    super();
  }

  public close() {
    this.$emit("reject");
  }

  public async applyEvent() {
    this.isLoading = true;

    try {
      const response = await moneyService.payout(
        this.item.employee.id,
        this.item.boxId,
        this.model,
        "till"
      );
      if (response) {
        EventBus.$emit("print", {
          component: PayoutSlipTillComponent,
          props: {
            data: { user: this.item.employee, ...response }
          }
        });
        EventBus.$emit("reloadOpenTills");
        EventBus.$emit("reloadPayouts");
        this.$emit("resolve");
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      messagesService.renderErrorMessage(e);
    }
  }

  public requiredRule(value: number) {
    return !!value || this.$t("required_field_error");
  }

  public validAmountRule(value: number) {
    const min = 1;
    if (+value < min) {
      return this.$t("money_manager.min_amount_error", { amount: min });
    }
    if (this.item) {
      return (
        value <= +this.item.cash_retail || this.$t("available_amount_exceeded")
      );
    }

    return true;
  }
}

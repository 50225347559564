import { pusherEvents } from "@/enums/pusherEvents";
import { currencyFilter } from "@/filters/currency.filter";
import { FormattedTill, MoneyManagerTabData, Till } from "@/interfaces/money";
import { EventBus } from "@/internal";
import { moneyService } from "@/services/money.service";
import { Callback } from "@/types/types";
import { truncate } from "@/utils/math.utils";
import {
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import find from "lodash/find";
import reverse from "lodash/reverse";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./CloseTill.template.vue";

const namespace = "MoneyModule";

@Component({
  mixins: [Template],
  components: {
    TableComponent
  },
  filters: { currencyFilter },
  inject: ["$changes"]
})
export default class CloseTillComponent extends Vue {
  public paginate = moneyService.paginationAction();
  @Prop({ required: true }) public data!: MoneyManagerTabData;
  @Prop({ default: false }) public onDisplay!: boolean;

  @Getter("closedTillsData", { namespace })
  public closedTillsData!: Till[];
  @Getter("closedTillsPagination", { namespace })
  public pagination!: TablePagination;
  @Getter("closedTillsLoading", { namespace })
  public loading!: boolean;
  @Action("loadClosedTills", { namespace }) private loadClosedTills!: Callback;

  @Watch("onDisplay", { immediate: true })
  public async resetQuery() {
    if (this.onDisplay) {
      moneyService.resetQuery();
      this.$changes.watch(
        [pusherEvents.closeTill, pusherEvents.removeManagerReview],
        this.loadClosedTills
      );
      if (!this.closedTillsData.length) {
        this.paginate({
          currentPage: 1,
          itemsPerPage: 10
        });
      }
      this.loadClosedTills();
    }
  }

  get formattedRecords() {
    const formatted: FormattedTill[] = [];
    each(this.closedTillsData, (record: Till) => {
      const reversedEvents = reverse(record.events);
      const recordCash = cloneDeep(record.cash);
      const employeeCount = find(reversedEvents, ["type", "EMPLOYEE_COUNT"]);
      const difference =
        truncate(+employeeCount!.amount, 2) - truncate(recordCash, 2);
      const differenceSign = Math.sign(difference);
      formatted.push({
        employee: record.assigned_user!,
        cash_assigned: find(reversedEvents, ["type", "OPEN"])!.amount,
        cash_retail: recordCash,
        updated_at: record.updated_at,
        employee_count: employeeCount!.amount,
        difference: Math.abs(difference),
        differenceSign,
        money_detail: employeeCount!.money_detail,
        boxData: record
      });
    });

    return formatted;
  }

  public sort(header: TableHeader) {
    this.$emit("sort", { header, dispatcher: `${namespace}/loadClosedTills` });
  }

  public changePagination(pagination: TablePagination) {
    this.paginate({
      currentPage: pagination.currentPage,
      itemsPerPage: pagination.itemsPerPage,
      dispatchAction: `${namespace}/loadClosedTills`
    });
  }

  protected mounted() {
    EventBus.$on("reloadClosedTills", () => {
      this.loadClosedTills();
    });
  }
}

import { pusherEvents } from "@/enums/pusherEvents";
import { MoneyEvent, MoneyManagerTabData } from "@/interfaces/money";
import { EventBus } from "@/internal";
import { moneyService } from "@/services/money.service";
import { Callback } from "@/types/types";
import {
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./Payout.template.vue";

const namespace = "MoneyModule";

@Component({
  mixins: [Template],
  components: {
    TableComponent
  },
  inject: ["$changes"]
})
export default class PayoutComponent extends Vue {
  @Prop({ required: true }) public data!: MoneyManagerTabData;
  @Prop({ default: false }) public onDisplay!: boolean;
  public paginate = moneyService.paginationAction();
  @Getter("payoutsData", { namespace })
  public payoutsData!: MoneyEvent[];
  @Getter("payoutsPagination", { namespace })
  public pagination!: TablePagination;
  @Getter("payoutsLoading", { namespace })
  public loading!: boolean;
  @Action("loadPayouts", { namespace }) private loadPayouts!: Callback;

  @Watch("onDisplay", { immediate: true })
  public async resetQuery() {
    if (this.onDisplay) {
      moneyService.resetQuery();
      this.$changes.watch(
        [pusherEvents.removePayoutFromTill, pusherEvents.removePayoutFromSafe],
        this.loadPayouts
      );

      if (!this.payoutsData.length) {
        this.paginate({
          currentPage: 1,
          itemsPerPage: 10
        });
      }
      this.loadPayouts();
    }
  }

  public sort(header: TableHeader) {
    this.$emit("sort", { header, dispatcher: `${namespace}/loadPayouts` });
  }

  public changePagination(pagination: TablePagination) {
    this.paginate({
      currentPage: pagination.currentPage,
      itemsPerPage: pagination.itemsPerPage,
      dispatchAction: `${namespace}/loadPayouts`
    });
  }

  protected mounted() {
    EventBus.$on("reloadPayouts", this.loadPayouts);
  }
}

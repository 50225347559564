import { EventBus } from "@/internal";
import { CloseTillFormModel } from "@/metadata/money";
import { MethodSales, moneyService } from "@/services/money.service";
import { truncate } from "@/utils/math.utils";
import forIn from "lodash/forIn";
import round from "lodash/round";
import { subtract } from "mathjs";
import { Component, Prop, Vue } from "vue-property-decorator";
import TillReviewSlipComponent from "../print/till-review/TillReviewSlip.component";
import Template from "./TillReview.template.vue";

const billsMap: { [key: string]: number } = {
  ones: 1,
  fives: 5,
  tens: 10,
  twenties: 20,
  fifties: 50,
  hundreds: 100
};

interface TillData extends MethodSales {
  cash_breakdown?: {
    till_opening: number;
    payouts: number;
    removes: number;
    expected: number;
  };
}
@Component({
  mixins: [Template]
})
export default class TillReviewComponent extends Vue {
  @Prop({ required: true })
  public item!: any;
  @Prop({ required: true })
  public tillData!: TillData;

  public isValid = false;
  public loading = false;
  public differenceClass = "";
  public differenceSimbol = "";
  public reviewDifferenceClass = "";
  public reviewDifferenceSimbol = "";
  public paymentMethodsSales = {};
  public reviewDifferenceRaw: number = 0;
  public closeTillForm: CloseTillFormModel = {
    bills: {
      ones: "",
      fives: "",
      tens: "",
      twenties: "",
      fifties: "",
      hundreds: ""
    },
    total_change: "",
    other_amount: "",
    review_count: "",
    review_difference: ""
  };

  get startingCash() {
    return this.item.cash_assigned;
  }

  get expectedCash() {
    return this.item.cash_retail;
  }

  get employeeCount() {
    return this.item.employee_count;
  }

  get reviewCount() {
    if (!this.isValid) {
      return 0;
    }
    let bills = 0;
    forIn(this.closeTillForm.bills, (value: string, key: string) => {
      bills = bills + billsMap[key] * +value;
    });
    return round(
      bills +
        Number(this.closeTillForm.other_amount) +
        Number(this.closeTillForm.total_change),
      2
    );
  }

  get reviewDifference() {
    const employeeCount = +this.employeeCount;
    if (!employeeCount) {
      this.reviewDifferenceSimbol = "";
      this.reviewDifferenceClass = "";
      return "0.00";
    }
    this.reviewDifferenceRaw = Number(
      subtract(Number(this.reviewCount), Number(this.expectedCash))
    );
    if (this.reviewDifferenceRaw > 0) {
      this.reviewDifferenceSimbol = "+";
      this.reviewDifferenceClass = "positive";
    } else if (this.reviewDifferenceRaw < 0) {
      this.reviewDifferenceSimbol = "-";
      this.reviewDifferenceClass = "negative";
    } else {
      this.reviewDifferenceSimbol = "";
      this.reviewDifferenceClass = "";
    }

    return Math.abs(this.reviewDifferenceRaw);
  }

  get notEmptyNums() {
    return (
      this.closeTillForm.bills.ones !== "" ||
      this.closeTillForm.bills.fives !== "" ||
      this.closeTillForm.bills.tens !== "" ||
      this.closeTillForm.bills.twenties !== "" ||
      this.closeTillForm.bills.fifties !== "" ||
      this.closeTillForm.bills.hundreds !== "" ||
      this.closeTillForm.total_change !== "" ||
      this.closeTillForm.other_amount !== ""
    );
  }

  public valueColors(value: number): string | null {
    return value > 0 ? "positive" : value < 0 ? "negative" : null;
  }

  public onlyNumber(event: KeyboardEvent) {
    if (
      isNaN(+event.key) &&
      !["."].includes(event.key) &&
      event.key.length === 1
    ) {
      event.preventDefault();
    } else if (!isNaN(+event.key) || ["."].includes(event.key)) {
      event.preventDefault();
      return event.key;
    }
  }

  public formatOther(event: KeyboardEvent) {
    const value = this.onlyNumber(event);
    if (value) {
      this.closeTillForm.other_amount = this.limitNumber(
        this.closeTillForm.other_amount + value
      );
    }
  }

  public formatTotal(event: KeyboardEvent) {
    const value = this.onlyNumber(event);
    if (value) {
      this.closeTillForm.total_change = this.limitNumber(
        this.closeTillForm.total_change + value
      );
    }
  }

  public limitNumber(num: string) {
    const hasMoreDots = num.includes("..");
    if (hasMoreDots) {
      return num.replace("..", ".");
    }
    if (num.includes(".")) {
      const splitNum = num.split(".");
      splitNum[0] = splitNum[0].substr(0, 7);
      splitNum[1] = splitNum[1].substr(0, 2);
      return splitNum.join(".");
    }
    return num.substr(0, 7);
  }

  public async recount() {
    const response = await moneyService.recountTill(
      this.item.boxData.id,
      this.item.employee.id
    );
    if (response) {
      this.$emit("resolve");
      EventBus.$emit("reloadOpenTills");
      EventBus.$emit("reloadClosedTills");
    }
  }

  public async accept() {
    this.loading = true;
    const response = await moneyService.acceptReview(this.item.boxData.id, {
      ...this.closeTillForm,
      review_count: this.reviewCount,
      review_difference: this.reviewDifferenceSimbol + this.reviewDifference
    });

    if (response) {
      EventBus.$emit("print", {
        component: TillReviewSlipComponent,
        props: {
          data: {
            ...response,
            employee: this.item.employee
          }
        }
      });
      EventBus.$emit("reloadClosedTills");
      EventBus.$emit("reloadSafe");
      this.$emit("resolve");
      this.loading = false;
    }
  }

  protected mounted() {
    this.differenceClass =
      this.item.differenceSign < 0
        ? "negative"
        : this.item.differenceSign > 0
        ? "positive"
        : "";
    this.closeTillForm = this.item.money_detail
      ? JSON.parse(this.item.money_detail)
      : this.closeTillForm;
    this.paymentMethodsSales = moneyService.getSalesByMethod(this.tillData);
  }
}

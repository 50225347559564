import { moneyEventTypes } from "@/enums/moneyManager";
import { User } from "@/interfaces/user";
import { EventBus } from "@/internal";
import { messagesService } from "@/services/messages.service";
import { moneyService } from "@/services/money.service";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { PayoutSlipComponent } from "../print/payout/PayoutSlip.component";
import Template from "./PayoutSafe.template.vue";

const namespace = "MoneyModule";
@Component({
  mixins: [Template]
})
export default class PayoutSafeComponent extends Vue {
  @Getter("user", { namespace: "AuthModule" }) public user!: User;
  @Getter("safeData", { namespace })
  public safeData!: {
    events: any[];
    cash: number;
    boxId: number;
    employee: User;
  };
  public isFormValid = false;
  public isLoading = false;
  public model: {
    amount: string;
    sub_type: string;
    description: string;
  } = {
    amount: "",
    sub_type: moneyEventTypes.PayoutSafe,
    description: ""
  };

  public close() {
    this.$emit("reject");
  }

  public async applyEvent() {
    this.isLoading = true;
    try {
      const response = await moneyService.payout(
        this.safeData.employee.id,
        this.safeData.boxId,
        this.model,
        "safe"
      );
      if (response) {
        this.$emit("resolve");
        EventBus.$emit("print", {
          component: PayoutSlipComponent,
          props: {
            data: { user: this.safeData.employee, ...response }
          }
        });
        EventBus.$emit("reloadSafe");
        EventBus.$emit("reloadPayouts");
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      messagesService.renderErrorMessage(e);
    }
  }

  public requiredRule(value: number) {
    return !!value || this.$t("required_field_error");
  }

  public validAmountRule(value: number) {
    const min = 1;
    if (+value < min) {
      return this.$t("money_manager.min_amount_error", { amount: min });
    }
    if (this.safeData) {
      return (
        value <= this.safeData.cash || this.$t("available_amount_exceeded")
      );
    }

    return true;
  }

  public mounted() {
    this.safeData.employee = this.user;
  }
}

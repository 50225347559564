import { User } from "@/interfaces/user";
import { EventBus } from "@/internal";
import { messagesService } from "@/services/messages.service";
import { moneyService } from "@/services/money.service";
import { Callback } from "@/types/types";
import { fnsFormatDate } from "@/utils/date-fns.utils";
import debounce from "lodash/debounce";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { CreateTillSlipComponent } from "../print/create/CreateTillSlip.component";
import Template from "./OpenTillModal.template.vue";

const namespace = "UserModule";
@Component({
  mixins: [Template]
})
export default class OpenTillModalComponent extends Vue {
  @Getter("users", { namespace })
  public users!: User[];
  @Getter("loading", { namespace })
  public usersLoading!: boolean;
  @Getter("safeData", { namespace: "MoneyModule" })
  public safeData!: { events: any[]; cash: number; boxId: number };
  public isFormValid = false;
  public tills: any[] = [];
  public isLoading = false;
  public searchUsers = null;
  public model: {
    amount: string;
    selectedUser: number | null;
  } = {
    amount: "",
    selectedUser: null
  };
  @Action("searchUsers", { namespace })
  private searchUsersAction!: Callback;
  private dsearchUsers = debounce((val: string) => {
    if (((val && val.length > 2) || !val) && !this.model.selectedUser) {
      this.searchUsersAction(val);
    }
  }, 500);
  constructor() {
    super();
  }

  public close() {
    this.$emit("reject");
  }

  public itemText(item: any) {
    return `${item.name} (${item.email})`;
  }

  public async open() {
    this.isLoading = true;
    let tillId;
    try {
      // If no tills are available a new one must be created
      const newTill = await moneyService.createTill({
        name: `Till${fnsFormatDate(new Date(), "yyyyMMdd HHmmss")}`,
        safeId: this.safeData.boxId,
        assignedId: +this.model.selectedUser!
      });
      tillId = newTill && newTill.id;
      if (tillId) {
        const response = await moneyService.openTill(tillId, {
          user_id: this.model.selectedUser!,
          amount: this.model.amount
        });
        if (response) {
          EventBus.$emit("print", {
            component: CreateTillSlipComponent,
            props: {
              data: response
            }
          });
          EventBus.$emit("reloadOpenTills");
          EventBus.$emit("reloadSafe");
          this.$emit("resolve");
        }
        this.isLoading = false;
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      messagesService.renderErrorMessage(e);
    }
  }

  public requiredRule(value: any) {
    return !!value || this.$t("required_field").toString();
  }

  public validAmountRule(value: number) {
    const min = 1;
    if (+value < min) {
      return this.$t("money_manager.min_amount_error", { amount: min });
    }
    if (this.safeData) {
      return (
        value <= +this.safeData.cash || this.$t("available_amount_exceeded")
      );
    }

    return true;
  }

  @Watch("searchUsers")
  protected searchUsersW(val: string, oldval: string) {
    if (!val) {
      this.model.selectedUser = null;
    }
    this.dsearchUsers(val);
  }

  protected async mounted() {
    this.searchUsersAction("");
  }
}

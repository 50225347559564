import { MoneyPrintMixin } from "@/components/money/mixins/print.mixin";
import startCase from "lodash/startCase";
import { Component, Mixins } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./TillReviewSlip.template.vue";

@Component({
  mixins: [Template],
  filters: {
    startCase
  }
})
export default class TillReviewSlipComponent extends Mixins(MoneyPrintMixin) {
  @Getter("user", { namespace: "AuthModule" })
  public user!: Location;

  protected mounted() {
    this.$emit("readyToPrint");
  }
}

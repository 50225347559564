import { MoneyPrintMixin } from "@/components/money/mixins/print.mixin";
import { Component, Mixins } from "vue-property-decorator";
import Template from "./CreateTillSlip.template.vue";
@Component({
  mixins: [Template]
})
export class CreateTillSlipComponent extends Mixins(MoneyPrintMixin) {
  protected created() {
    this.$emit("readyToPrint");
  }
}

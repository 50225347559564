import TillReviewComponent from "@/components/money/manager/close-till/till-review/TillReview.component";
import OpenTillAddRemoveComponent from "@/components/money/manager/open-till/add-remove/OpenTillAddRemove.component";
import ManagerCloseTill from "@/components/money/manager/open-till/manager-close-till/ManagerCloseTill.component";
import PayoutTillComponent from "@/components/money/manager/open-till/payout-till/PayoutTill.component";
import { PayoutSlipTillComponent } from "@/components/money/manager/open-till/print/payout/PayoutSlipTill.component";
import { UndoCreateTillSlipComponent } from "@/components/money/manager/open-till/print/undo/UndoCreateTillSlip.component";
import VoidSafeSlipComponent from "@/components/money/manager/payout/print/voidSafe/VoidSafeSlip.component";
import { VoidTillSlipComponent } from "@/components/money/manager/payout/print/voidTill/VoidTillSlip.component";
import { PayoutReasonComponent } from "@/components/money/manager/payout/reason/PayoutReason.component";
import { AddRemoveSlipComponent } from "@/components/money/manager/safe/print/addRemove/AddRemoveSlip.component";
import { PayoutSlipComponent } from "@/components/money/manager/safe/print/payout/PayoutSlip.component";
import { moneyEventTypes } from "@/enums/moneyManager";
import { policyList } from "@/enums/permissions";
import { FormattedTill, MoneyEvent } from "@/interfaces/money";
import { EventBus, store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import {
  TableAction,
  TableItem,
  TableSuccessModalResponse
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import Vue from "vue";
import { messagesService } from "./messages.service";
import { moneyService } from "./money.service";

class MoneyActionsService extends Vue {
  public getSafeRowActions(): TableAction[] {
    return [
      {
        icon: "fal fa-undo",
        id: "btn_undo_safe",
        visibleCondition: row =>
          (!(row.item.cashless_sales || !!row.item.voided) ||
            (!row.item.voided &&
              (row.item.sub_type === "FROM_TILL_TO_SAFE" ||
                row.item.sub_type === "WITHDRAWAL_FROM_SAFE"))) &&
          store.getters["PermissionsModule/hasPermission"](
            policyList.undoSafeAction
          ),
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n.t("undo_action").toString(),
          modalSuccessText: "yes",
          modalIdSuccess: "btn_select_yes",
          modalSuccessAction: async (arg: TableSuccessModalResponse) => {
            if (
              arg.item.type === "REMOVE" &&
              arg.item.sub_type === "FROM_SAFE_TO_TILL"
            ) {
              try {
                const pinText = {
                  title: "money_manager.undo_action",
                  message: "money_manager.undo_action_message"
                };
                const response = await moneyService.closeOpenTill(
                  arg.item.referred_till_id!,
                  pinText
                );
                if (response) {
                  EventBus.$emit("print", {
                    component: UndoCreateTillSlipComponent,
                    props: {
                      data: {
                        // no tilldata info on safe events
                        ...response
                      }
                    }
                  });
                  EventBus.$emit("reloadOpenTills");
                  EventBus.$emit("reloadSafe");
                }
                arg.unselectModal();
              } catch (e) {
                messagesService.renderErrorMessage(e);
              }
            } else {
              try {
                const opositeAction =
                  arg.item.type === "ADD"
                    ? {
                        action: "REMOVE",
                        sub_type: moneyEventTypes.Withdrawal
                      }
                    : {
                        action: "ADD",
                        sub_type: moneyEventTypes.DepositSafe
                      };

                const undoMsg = this.setUndoMsg(arg.item);

                const response = await moneyService.addRemoveSafe(
                  arg.item.box_id,
                  {
                    action: opositeAction.action,
                    amount: String(arg.item.amount),
                    sub_type: opositeAction.sub_type,
                    undo: undoMsg
                  },
                  {
                    tillID: arg.item.referred_till_id,
                    eventID: arg.item.id
                  }
                );
                if (response) {
                  EventBus.$emit("print", {
                    component: AddRemoveSlipComponent,
                    props: {
                      data: response
                    }
                  });
                  EventBus.$emit("reloadSafe");
                }
                arg.unselectModal();
              } catch (e) {
                messagesService.renderErrorMessage(e);
              }
            }
          },
          modalCancelText: "no",
          modalIdCancel: "btn_select_no"
        }
      }
    ];
  }

  public getSafeGeneralActions(
    add: () => void,
    payout: () => void
  ): TableAction[] {
    return [
      {
        icon: "fal fa-dollar-sign",
        id: "btn_options_safe",
        tooltip: i18n.t("add/remove_money").toString(),
        action: add,
        visibleCondition: () => {
          return (
            !!store.getters["MoneyModule/safeData"].boxId &&
            (store.getters["PermissionsModule/hasPermission"](
              policyList.addMoneyToSafe
            ) ||
              store.getters["PermissionsModule/hasPermission"](
                policyList.removeMoneyFromSafe
              ))
          );
        }
      },
      {
        icon: "fal fa-hand-holding-usd",
        id: "btn_payout_safe",
        tooltip: i18n.t("payout_from_safe").toString(),
        action: payout,
        visibleCondition: arg => {
          return (
            store.getters["PermissionsModule/hasPermission"](
              policyList.payoutFromSafe
            ) && !!store.getters["MoneyModule/safeData"].cash
          );
        }
      }
    ];
  }

  public getOpenTillGeneralActions(action: () => void): TableAction[] {
    return [
      {
        icon: "fal fa-cash-register",
        id: "btn_open_till",
        tooltip: i18n.t("open_till").toString(),
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.openATill
          ),
        action
      }
    ];
  }

  public getOpenTillActions(): TableAction[] {
    return [
      {
        icon: "fal fa-dollar-sign",
        id: "btn_addremove_money",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.addMoneyToTill
          ) ||
          store.getters["PermissionsModule/hasPermission"](
            policyList.removeMoneyFromTill
          ),
        tooltip: i18n.t("add/remove_money").toString(),
        action: (arg: FormattedTill) => {
          this.$modals
            .load(
              OpenTillAddRemoveComponent,
              {
                size: "normal",
                positionY: "top"
              },
              {
                item: arg
              }
            )
            .catch(() => {
              /* No handler for this modal*/
            });
        }
      },
      {
        icon: "fal fa-hand-holding-usd",
        id: "btn_payout_till",
        tooltip: i18n.t("payout_from_tills").toString(),
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.payoutFromTill
          ),
        action: (arg: FormattedTill) => {
          this.$modals
            .load(
              PayoutTillComponent,
              {
                size: "normal",
                positionY: "top"
              },
              {
                item: arg
              }
            )
            .catch(() => {
              /* No handler for this modal*/
            });
        }
      },
      {
        icon: "fal fa-envelope-open-dollar",
        id: "btn_close_till",
        tooltip: i18n.t("close_till").toString(),
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.closeATill
          ),
        action: async (arg: FormattedTill) => {
          const tillData = await moneyService.getTillById(arg.boxId!);
          this.$modals
            .load(
              ManagerCloseTill,
              {
                size: "fit",
                positionY: "center"
              },
              {
                item: arg,
                tillData
              }
            )
            .catch(() => {
              /* No handler for this modal*/
            });
        }
      },
      {
        icon: "fal fa-undo",
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n.t("undo_action").toString(),
          modalSuccessText: "yes",
          modalSuccessAction: async (arg: TableSuccessModalResponse) => {
            const pinText = {
              title: "money_manager.undo_till_creation",
              message: "money_manager.undo_till_creation_pin"
            };
            const response = await moneyService.closeOpenTill(
              arg.item.boxId!,
              pinText
            );
            if (response) {
              EventBus.$emit("print", {
                component: UndoCreateTillSlipComponent,
                props: {
                  data: {
                    title: "undo_create_till",
                    till_data: arg,
                    ...response
                  }
                }
              });
              EventBus.$emit("reloadOpenTills");
              EventBus.$emit("reloadSafe");
            }
            arg.unselectModal();
          },
          modalCancelText: "no"
        },
        visibleCondition: (arg: any) => {
          return (
            arg.item.canUndo &&
            store.getters["PermissionsModule/hasPermission"](
              policyList.undoOpeningATill
            )
          );
        }
      }
    ];
  }

  public getCloseTillActions(): TableAction[] {
    return [
      {
        icon: "fal fa-check-circle",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.reviewAClosedTill
          ),
        action: async (arg: FormattedTill) => {
          const tillData = await moneyService.getTillById(arg.boxData!.id);
          this.$modals
            .load(
              TillReviewComponent,
              {
                size: "fit",
                positionY: "center"
              },
              {
                item: arg,
                tillData
              }
            )
            .catch(() => {
              /* No handler for this modal*/
            });
        }
      }
    ];
  }

  public getPayoutActions(): TableAction[] {
    return [
      {
        icon: "fal fa-eye",
        id: "btn_view_payout_reason",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.viewPayoutReason
          ),
        tooltip: i18n.t("money_manager.tooltips.view_reason").toString(),
        action: (arg: FormattedTill) => {
          this.$modals
            .load(
              PayoutReasonComponent,
              {
                size: "normal",
                positionY: "top"
              },
              {
                payout: arg
              }
            )
            .catch(() => {
              /* No handler for this modal*/
            });
        }
      },
      {
        icon: "fal fa-print",
        id: "btn_print_payout",
        tooltip: i18n.t("money_manager.tooltips.print_payout").toString(),
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.printPayoutReceipt
          ),
        action: (arg: MoneyEvent) => {
          EventBus.$emit("print", {
            component:
              arg.sub_type === moneyEventTypes.PayoutSafe
                ? PayoutSlipComponent
                : PayoutSlipTillComponent,
            props: {
              data: cloneDeep(arg)
            }
          });
        }
      },
      {
        icon: "fal fa-ban",
        id: "btn_void_payout",
        tooltip: i18n.t("money_manager.tooltips.void_payout").toString(),
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.voidPayout
          ),
        action: async (arg: MoneyEvent) => {
          const response = await moneyService.voidPayout(arg.id);
          if (response) {
            EventBus.$emit("print", {
              component:
                arg.sub_type === moneyEventTypes.PayoutSafe
                  ? VoidSafeSlipComponent
                  : VoidTillSlipComponent,
              props: {
                data: response
              }
            });
            if (arg.sub_type === moneyEventTypes.PayoutTill) {
              EventBus.$emit("reloadOpenTills");
            }
            EventBus.$emit("reloadSafe");
            EventBus.$emit("reloadPayouts");
          }
        }
      }
    ];
  }

  protected setUndoMsg(item: TableItem): string {
    if (item.type === "ADD") {
      return item.sub_type === "DEPOSIT_TO_SAFE"
        ? "UNDO_ADD_MONEY_TO_SAFE"
        : "UNDO_REMOVE_MONEY_FROM_TILL";
    } else if (item.type === "REMOVE") {
      return item.sub_type === "PAYOUT_FROM_SAFE"
        ? "UNDO_PAYOUT_FROM_SAFE"
        : item.description !== null
        ? "UNDO_ADD_MONEY_FROM_TILL"
        : "UNDO_REMOVE_MONEY_FROM_SAFE";
    }
    return "UNDO";
  }
}

export const moneyActionService: MoneyActionsService = new MoneyActionsService();

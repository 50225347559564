import { MoneyPrintMixin } from "@/components/money/mixins/print.mixin";
import { Component, Mixins } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./ManagerCloseTillSlip.template.vue";

@Component({
  mixins: [Template]
})
export default class ManagerCloseTillSlipComponent extends Mixins(
  MoneyPrintMixin
) {
  @Getter("user", { namespace: "AuthModule" })
  public user!: Location;

  protected mounted() {
    this.$emit("readyToPrint");
  }
}

import { MoneyPrintMixin } from "@/components/money/mixins/print.mixin";
import { Component, Mixins } from "vue-property-decorator";
import Template from "./AddRemoveTillSlip.template.vue";

@Component({
  mixins: [Template]
})
export default class AddRemoveTillSlipComponent extends Mixins(
  MoneyPrintMixin
) {
  public slipTitle = "money_manager.slips.add_money_till";
  public totalLabel = "money_manager.slips.total_removed";
  public totalLabelTill = "money_manager.slips.total_added";
  public actionText = "money_manager.slips.add_till_safe";

  get safeAmount() {
    return this.data.amount * -1;
  }

  protected created() {
    if (this.data.type === "REMOVE") {
      this.slipTitle = "money_manager.slips.remove_money_till";
      this.totalLabel = "money_manager.slips.total_added";
      this.totalLabelTill = "money_manager.slips.total_removed";
      this.data.amount = this.data.amount * -1;
      this.actionText = "money_manager.slips.remove_till_safe";
    }
    this.$emit("readyToPrint");
  }
}

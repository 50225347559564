import { moneyEventTypes } from "@/enums/moneyManager";
import { policyList } from "@/enums/permissions";
import { EventBus } from "@/internal";
import { messagesService } from "@/services/messages.service";
import { moneyService } from "@/services/money.service";
import { BooleanCheck } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { AddRemoveSlipComponent } from "../print/addRemove/AddRemoveSlip.component";
import Template from "./SafeAddRemove.template.vue";

const namespace = "MoneyModule";

@Component({
  mixins: [Template]
})
export default class SafeAddRemoveComponent extends Vue {
  @Getter("safeData", { namespace })
  public safeData!: { events: any[]; cash: number; boxId: number };
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  public addMoneyDisabled = false;
  public removeMoneyDisabled = false;
  public isFormValid = false;
  public isLoading = false;
  public model: { action: string; amount: string; sub_type: string } = {
    action: "add",
    amount: "",
    sub_type: ""
  };

  public requiredRule(value: number) {
    return !!value || this.$t("required_field_error");
  }

  public validAmountRule(value: number) {
    const min = 1;
    const max = 1000001;
    if (+value < min) {
      return this.$t("money_manager.min_amount_error", { amount: min });
    }
    if (+value >= max) {
      return this.$t("money_manager.max_amount_error", { amount: max });
    }
    if (this.model.action === "remove" && this.safeData) {
      return (
        value <= this.safeData.cash || this.$t("available_amount_exceeded")
      );
    }

    return true;
  }

  public validate() {
    // @ts-ignore
    if (this.$refs.form) {
      // @ts-ignore
      this.$refs.form.validate();
    }
  }

  public closeModal() {
    this.$emit("reject");
    if (this.$refs.form) {
      // @ts-ignore
      this.$refs.form.reset();
    }
  }

  public async applyEvent() {
    this.isLoading = true;
    try {
      const response = await moneyService.addRemoveSafe(
        this.safeData.boxId,
        this.model
      );

      if (response) {
        EventBus.$emit("print", {
          component: AddRemoveSlipComponent,
          props: {
            data: response
          }
        });
        EventBus.$emit("reloadSafe");
        this.$emit("resolve");
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      messagesService.renderErrorMessage(e);
    }
  }

  get selectLabel() {
    return this.model.action === "add"
      ? "money_manager.select_source"
      : "money_manager.select_method";
  }

  get subTypeItems() {
    return this.model.action === "add"
      ? [
          {
            text: this.$t(
              `money_manager.${moneyEventTypes.DepositSafe.toLowerCase()}`
            ).toString(),
            value: moneyEventTypes.DepositSafe
          }
        ]
      : [
          {
            text: this.$t(
              `money_manager.${moneyEventTypes.Withdrawal.toLowerCase()}`
            ).toString(),
            value: moneyEventTypes.Withdrawal
          },
          {
            text: this.$t(
              `money_manager.${moneyEventTypes.CashPickup.toLowerCase()}`
            ).toString(),
            value: moneyEventTypes.CashPickup
          }
        ];
  }
  public mounted() {
    this.addMoneyDisabled = !this.hasPermission(policyList.addMoneyToSafe);
    this.model.action = (this.addMoneyDisabled && "remove") || "add";
    this.removeMoneyDisabled = !this.hasPermission(
      policyList.removeMoneyFromSafe
    );
  }
}

import { MoneyPrintMixin } from "@/components/money/mixins/print.mixin";
import { Component, Mixins } from "vue-property-decorator";
import Template from "./VoidSafeSlip.template.vue";

@Component({
  mixins: [Template]
})
export default class VoidSafeSlipComponent extends Mixins(MoneyPrintMixin) {
  protected created() {
    this.$emit("readyToPrint");
  }
}

import { MoneyPrintMixin } from "@/components/money/mixins/print.mixin";
import { Component, Mixins } from "vue-property-decorator";
import Template from "./PayoutSlip.template.vue";

@Component({
  mixins: [Template]
})
export class PayoutSlipComponent extends Mixins(MoneyPrintMixin) {
  protected created() {
    this.$emit("readyToPrint");
    this.data.amount = this.data.amount * -1;
  }
}

import { MoneyPrintMixin } from "@/components/money/mixins/print.mixin";
import { Component, Mixins } from "vue-property-decorator";
import Template from "./AddRemoveSlip.template.vue";

@Component({
  mixins: [Template]
})
export class AddRemoveSlipComponent extends Mixins(MoneyPrintMixin) {
  public slipTitle = "money_manager.slips.add_money_safe";
  public totalLabel = "money_manager.slips.total_added";

  protected created() {
    if (this.data.type === "REMOVE") {
      this.slipTitle = "money_manager.slips.remove_money_safe";
      this.totalLabel = "money_manager.slips.total_removed";
      this.data.amount = this.data.amount * -1;
    }
    this.$emit("readyToPrint");
  }
}

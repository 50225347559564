import { EventBus } from "@/internal";
import { CloseTillFormModel } from "@/metadata/money";
import { MethodSales, moneyService } from "@/services/money.service";
import { truncate } from "@/utils/math.utils";
import forIn from "lodash/forIn";
import round from "lodash/round";
import { subtract } from "mathjs";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./ManagerCloseTill.template.vue";
import ManagerCloseTillSlipComponent from "./print/ManagerCloseTillSlip.component";
const billsMap: { [key: string]: number } = {
  ones: 1,
  fives: 5,
  tens: 10,
  twenties: 20,
  fifties: 50,
  hundreds: 100
};
interface TillData extends MethodSales {
  cash_breakdown?: {
    till_opening: number;
    payouts: number;
    removes: number;
    expected: number;
  };
}
@Component({
  mixins: [Template]
})
export default class ManagerCloseTill extends Vue {
  @Prop({ required: true })
  public item!: any;
  @Prop({ required: true })
  public tillData!: TillData;
  public isValid = false;
  public differenceClass = "";
  public differenceSign = "";
  public paymentMethodsSales = {};
  public differenceRaw: number = 0;
  public closeTillForm: CloseTillFormModel = {
    bills: {
      ones: "",
      fives: "",
      tens: "",
      twenties: "",
      fifties: "",
      hundreds: ""
    },
    total_change: "",
    other_amount: ""
  };
  get expectedCash() {
    return this.item.cash_retail;
  }
  get difference() {
    if (this.managerCount !== 0 && !this.managerCount) {
      this.differenceSign = this.differenceClass = "";
      return "--";
    }
    this.differenceRaw = Number(
      subtract(Number(this.managerCount), Number(this.expectedCash))
    );
    if (this.differenceRaw > 0) {
      this.differenceClass = "positive";
      this.differenceSign = "+";
    } else if (this.differenceRaw < 0) {
      this.differenceClass = "negative";
      this.differenceSign = "-";
    } else {
      this.differenceSign = this.differenceClass = "";
    }
    return this.$options.filters!.currency(Math.abs(this.differenceRaw));
  }
  get managerCount() {
    if (!this.isValid) {
      return 0;
    }
    let bills = 0;
    forIn(this.closeTillForm.bills, (value: string, key: string) => {
      bills += billsMap[key] * +value;
    });
    return (
      bills +
      Number(this.closeTillForm.other_amount) +
      Number(this.closeTillForm.total_change)
    );
  }

  get notEmptyNums() {
    return (
      this.closeTillForm.bills.ones !== "" ||
      this.closeTillForm.bills.fives !== "" ||
      this.closeTillForm.bills.tens !== "" ||
      this.closeTillForm.bills.twenties !== "" ||
      this.closeTillForm.bills.fifties !== "" ||
      this.closeTillForm.bills.hundreds !== "" ||
      this.closeTillForm.total_change !== "" ||
      this.closeTillForm.other_amount !== ""
    );
  }

  public onlyNumber(event: KeyboardEvent) {
    if (
      isNaN(+event.key) &&
      !["."].includes(event.key) &&
      event.key.length === 1
    ) {
      event.preventDefault();
    } else if (!isNaN(+event.key) || ["."].includes(event.key)) {
      event.preventDefault();
      return event.key;
    }
  }

  public formatOther(event: KeyboardEvent) {
    const value = this.onlyNumber(event);
    if (value) {
      this.closeTillForm.other_amount = this.limitNumber(
        this.closeTillForm.other_amount + value
      );
    }
  }

  public formatTotal(event: KeyboardEvent) {
    const value = this.onlyNumber(event);
    if (value) {
      this.closeTillForm.total_change = this.limitNumber(
        this.closeTillForm.total_change + value
      );
    }
  }

  public limitNumber(num: string) {
    const hasMoreDots = num.includes("..");
    if (hasMoreDots) {
      return num.replace("..", ".");
    }
    if (num.includes(".")) {
      const splitNum = num.split(".");
      splitNum[0] = splitNum[0].substr(0, 7);
      splitNum[1] = splitNum[1].substr(0, 2);
      return splitNum.join(".");
    }
    return num.substr(0, 7);
  }

  public valueColors(value: number): string | null {
    return value > 0 ? "positive" : value < 0 ? "negative" : null;
  }
  public async accept() {
    const response = await moneyService.managerCloseTill(this.item.boxId, {
      ...this.closeTillForm,
      review_count: this.managerCount,
      review_difference: this.differenceRaw
    });
    if (response) {
      EventBus.$emit("print", {
        component: ManagerCloseTillSlipComponent,
        props: {
          data: {
            ...response,
            employee: this.item.employee
          }
        }
      });
      EventBus.$emit("reloadOpenTills");
      EventBus.$emit("reloadSafe");
      this.$emit("resolve");
    }
  }

  protected mounted() {
    this.differenceClass =
      this.item.differenceSign < 0 ? "negative" : "positive";
    this.closeTillForm = this.item.money_detail
      ? JSON.parse(this.item.money_detail)
      : this.closeTillForm;
    this.paymentMethodsSales = moneyService.getSalesByMethod(this.tillData);
  }
}

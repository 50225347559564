import { render, staticRenderFns } from "./UndoCreateTillSlip.template.vue?vue&type=template&id=6906e561&scoped=true&"
var script = {}
import style0 from "./UndoCreateTillSlip.template.vue?vue&type=style&index=0&id=6906e561&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6906e561",
  null
  
)

export default component.exports
import { pusherEvents } from "@/enums/pusherEvents";
import { EventBus } from "@/event-bus";
import { MoneyEvent, MoneyManagerTabData } from "@/interfaces/money";
import { moneyService } from "@/services/money.service";
import { Callback } from "@/types/types";
import {
  ActionsSubheaderComponent,
  PageTitleComponent,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./Safe.template.vue";

const namespace = "MoneyModule";
@Component({
  mixins: [Template],
  components: {
    ActionsSubheaderComponent,
    PageTitleComponent,
    TableComponent
  },
  inject: ["$changes"]
})
export default class MoneySafeComponent extends Vue {
  @Prop({ required: true }) public data!: MoneyManagerTabData;
  @Prop({ default: false }) public onDisplay!: boolean;

  public paginate = moneyService.paginationAction();

  @Getter("safeData", { namespace })
  public safeData!: { events: MoneyEvent[]; cash: number; boxId: number };
  @Getter("safePagination", { namespace })
  public pagination!: TablePagination;
  @Getter("safeLoading", { namespace })
  public loading!: boolean;
  @Action("loadSafe", { namespace }) private loadSafe!: Callback;

  @Watch("onDisplay", { immediate: true })
  public async resetQuery() {
    if (this.onDisplay) {
      this.$changes.watch(
        [
          pusherEvents.depositToSafe,
          pusherEvents.cashPickupWithdrawal,
          pusherEvents.removeWithdrawalFromSafe,
          pusherEvents.addFromTillToSafe,
          pusherEvents.addFromSafeToTill,
          pusherEvents.removeFromTillToSafe,
          pusherEvents.removePayoutFromSafe,
          pusherEvents.closeManagerReview
        ],
        this.loadSafe
      );
      moneyService.resetQuery();
      this.loadSafe();
    }
  }

  public filter(header: TableHeader) {
    this.$emit("sort", { header, dispatcher: `${namespace}/loadSafe` });
  }

  public changePagination(pagination: TablePagination) {
    this.paginate({
      currentPage: pagination.currentPage,
      itemsPerPage: pagination.itemsPerPage,
      dispatchAction: `${namespace}/loadSafe`
    });
  }

  protected mounted() {
    this.loadSafe();
    EventBus.$on("reloadSafe", this.loadSafe);
  }
}

import { ResetService } from "@/decorators/resetService.decorator";
import { MoneyEvent } from "@/interfaces/money";
import { EventBus } from "@/internal";
import {
  CloseTillTableMetadata,
  OpenTillTableMetadata,
  PayoutsMetadata,
  SafeTableMetadata
} from "@/metadata/money";
import { moneyActionService } from "@/services/money.actions.service";
import { moneyService } from "@/services/money.service";
import { Callback, PageNavAction } from "@/types/types";
import { OpenTillsSummary } from "@/vuex/modules/money/money.types";
import { TableHeader } from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  SeparatedBreakdownComponent,
  SeparatedItem
} from "./../../sharedComponents/separatedBreakdown/SeparatedBreakdown.component";
import CloseTillComponent from "./close-till/CloseTill.component";
import Template from "./MoneyManager.template.vue";
import OpenTillModalComponent from "./open-till/open/OpenTillModal.component";
import OpenTillComponent from "./open-till/OpenTill.component";
import PayoutComponent from "./payout/Payout.component";
import SafeAddRemoveComponent from "./safe/add-remove/SafeAddRemove.component";
import PayoutSafeComponent from "./safe/payout-safe/PayoutSafe.component";
import MoneySafeComponent from "./safe/Safe.component";

const namespace: string = "MoneyModule";

@Component({
  mixins: [Template]
})
@ResetService(moneyService)
export default class MoneyManagerComponent extends Vue {
  @Getter("safeData", { namespace })
  public safeData!: { events: MoneyEvent[]; cash: number };
  @Getter("openTillsSummary", { namespace })
  public openTillsSummary!: OpenTillsSummary;
  @Getter("payoutsLoading", { namespace })
  public payoutsLoading!: boolean;
  public activeTab = 0;
  public tableColors: string[] = ["#f2f2f2", "#f2f2f2", "#f2f2f2"];
  public tabsComponents: any[] = [];
  @Action("loadOpenTillsSummary", { namespace })
  private loadOpenTillsSummary!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  private setPageNav!: PageNavAction;

  constructor() {
    super();
    this.loadOpenTillsSummary();
  }

  public openModal(comp: any) {
    return () =>
      this.$modals.load(comp, {
        size: "fit",
        positionY: "top"
      });
  }

  protected async setTabsComponents() {
    this.tabsComponents = [
      {
        component: MoneySafeComponent,
        data: {
          actions: moneyActionService.getSafeRowActions(),
          headers: SafeTableMetadata,
          colors: this.tableColors,
          generalActions: moneyActionService.getSafeGeneralActions(
            this.openModal(SafeAddRemoveComponent),
            this.openModal(PayoutSafeComponent)
          )
        }
      },
      {
        component: OpenTillComponent,
        data: {
          actions: moneyActionService.getOpenTillActions(),
          headers: OpenTillTableMetadata,
          colors: this.tableColors,
          generalActions: moneyActionService.getOpenTillGeneralActions(
            this.openModal(OpenTillModalComponent)
          )
        }
      },
      {
        component: CloseTillComponent,
        data: {
          actions: moneyActionService.getCloseTillActions(),
          headers: CloseTillTableMetadata,
          colors: this.tableColors
        }
      },
      {
        component: PayoutComponent,
        data: {
          actions: moneyActionService.getPayoutActions(),
          headers: PayoutsMetadata,
          colors: this.tableColors
        }
      }
    ];
  }

  protected sortAndFetch(params: { header: TableHeader; dispatcher: string }) {
    const h = { ...params.header };

    if (h.value.includes("retail")) {
      h.value = "cash";
    }

    if (h.value.includes("type")) {
      h.value = "type";
    }

    moneyService.sortQuery(h, params.dispatcher);
  }

  /**
   * Gets breakdown items
   * @returns SeparatedItem[]
   */
  public get breakdownItems(): SeparatedItem[] {
    return [
      {
        hasCurrency: true,
        name: "money_manager.total_cash_safe",
        amount: () => this.safeData.cash
      },
      {
        hasCurrency: false,
        name: "money_manager.number_open_tills",
        amount: () =>
          (this.openTillsSummary && this.openTillsSummary.open_tills) || 0
      },
      {
        hasCurrency: true,
        name: "money_manager.cash_tills",
        amount: () =>
          (this.openTillsSummary && this.openTillsSummary.cash_in_tills) || 0
      }
    ];
  }

  protected mounted() {
    this.setTabsComponents();
    this.setPageNav({
      title: "money_manager.title",
      isLoading: () => this.payoutsLoading,
      secondaryActions: {
        component: SeparatedBreakdownComponent,
        props: {
          items: this.breakdownItems,
          loading: () => this.payoutsLoading
        }
      }
    });

    EventBus.$on("reloadOpenTills", () => {
      this.loadOpenTillsSummary();
    });
  }
}

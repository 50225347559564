import { policyList } from "@/enums/permissions";
import { EventBus } from "@/internal";
import { messagesService } from "@/services/messages.service";
import { moneyService } from "@/services/money.service";
import { BooleanCheck } from "@/types/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import AddRemoveTillSlipComponent from "../print/addRemove/AddRemoveTillSlip.component";
import Template from "./OpenTillAddRemove.template.vue";

@Component({
  mixins: [Template]
})
export default class OpenTillAddRemoveComponent extends Vue {
  @Prop({ default: null })
  public item!: any;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  public addMoneyDisabled = false;
  public removeMoneyDisabled = false;
  public isFormValid = false;
  public isLoading = false;
  public model: { action: string; amount: string } = {
    action: "add",
    amount: ""
  };

  public requiredRule(value: number) {
    return !!value || this.$t("required_field_error");
  }

  public validAmountRule(value: number) {
    const min = 1;
    if (+value < min) {
      return this.$t("money_manager.min_amount_error", { amount: min });
    }

    if (this.model.action === "remove" && this.item) {
      return (
        value <= this.item.cash_retail || this.$t("available_amount_exceeded")
      );
    }

    return true;
  }

  public validate() {
    // @ts-ignore
    this.$refs.form.validate();
  }

  public closeModal() {
    this.$emit("reject");
  }

  public async applyEvent() {
    this.isLoading = true;

    try {
      const response = await moneyService.AddRemoveToOpenTill(
        this.item,
        this.model
      );
      if (response) {
        EventBus.$emit("print", {
          component: AddRemoveTillSlipComponent,
          props: {
            data: response
          }
        });
        EventBus.$emit("reloadOpenTills");
        EventBus.$emit("reloadSafe");
        this.$emit("resolve");
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }

    this.isLoading = false;
  }
  public mounted() {
    this.addMoneyDisabled = !this.hasPermission(policyList.addMoneyToTill);
    this.model.action = (this.addMoneyDisabled && "remove") || "add";
    this.removeMoneyDisabled = !this.hasPermission(
      policyList.removeMoneyFromTill
    );
  }
}

import { pusherEvents } from "@/enums/pusherEvents";
import { FormattedTill, MoneyManagerTabData, Till } from "@/interfaces/money";
import { EventBus } from "@/internal";
import { moneyService } from "@/services/money.service";
import { Callback } from "@/types/types";
import {
  ActionsSubheaderComponent,
  PageTitleComponent,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import each from "lodash/each";
import find from "lodash/find";
import reverse from "lodash/reverse";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./OpenTill.template.vue";

const namespace = "MoneyModule";
@Component({
  mixins: [Template],
  components: {
    ActionsSubheaderComponent,
    PageTitleComponent,
    TableComponent
  },
  inject: ["$changes"]
})
export default class OpenTillComponent extends Vue {
  @Prop({ required: true }) public data!: MoneyManagerTabData;
  @Prop({ default: false }) public onDisplay!: boolean;

  public paginate = moneyService.paginationAction();

  @Getter("openTillsData", { namespace })
  public openTillsData!: Till[];
  @Getter("openTillsPagination", { namespace })
  public pagination!: TablePagination;
  @Getter("openTillsLoading", { namespace })
  public loading!: boolean;
  @Action("loadOpenTills", { namespace }) private loadOpenTills!: Callback;

  @Watch("onDisplay", { immediate: true })
  public async resetQuery() {
    if (this.onDisplay) {
      moneyService.resetQuery();
      this.$changes.watch(
        [
          pusherEvents.openTill,
          pusherEvents.addFromSafeToTill,
          pusherEvents.addManagerReview,
          pusherEvents.removeFromSafeToTill,
          pusherEvents.removePayoutFromTill,
          pusherEvents.removeFromTillToSafe,
          pusherEvents.addFromTillToSafe,
          pusherEvents.closeTill,
          pusherEvents.addDepositToTill
        ],
        () => {
          EventBus.$emit("reloadSafe");
          EventBus.$emit("reloadOpenTills");
        }
      );

      if (!this.openTillsData.length) {
        this.paginate({
          currentPage: 1,
          itemsPerPage: 10
        });
      }
      this.loadOpenTills();
    }
  }

  get formattedRecords() {
    const formatted: FormattedTill[] = [];
    if (this.openTillsData) {
      each(this.openTillsData, (record: Till) => {
        const reversedEvents = reverse(record.events);
        if (reversedEvents) {
          const openEvent =
            find(reversedEvents, ["type", "OPEN"]) || reversedEvents[0];
          formatted.push({
            employee: record.assigned_user!,
            cash_assigned: openEvent.amount,
            created_at: record.created_at,
            cash_retail: record.cash,
            boxId: record.id,
            canUndo: !record.cashless_sales
          });
        }
      });
    }
    return formatted;
  }

  public changePagination(pagination: TablePagination) {
    this.paginate({
      currentPage: pagination.currentPage,
      itemsPerPage: pagination.itemsPerPage,
      dispatchAction: `${namespace}/loadOpenTills`
    });
  }

  public sort(header: TableHeader) {
    this.$emit("sort", { header, dispatcher: `${namespace}/loadOpenTills` });
  }

  protected mounted() {
    EventBus.$on("reloadOpenTills", () => {
      this.loadOpenTills();
    });
  }
}
